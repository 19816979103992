import { Component } from '@angular/core';
import { Storage } from '@ionic/storage';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  constructor(
    private storage: Storage
  ) {
    storage.create();

    Promise.all([this.getDarkMode, this.getLocalImage]).then(() => {
      this.storage.get('darkmode').then(data => {
        if (data) {
          document.body.classList.toggle('dark');
        }
      });
    });

    this.storage.set('serverAddress', 'https://beton.devagro.be/api/api/');
    // this.storage.set('serverAddress', 'http://192.168.0.97:3004/api/');
    this.storage.set('localServerAddress', '');
  }

  async getDarkMode() {
    const dark = await this.storage.get('darkmode');

    if (dark === null || dark === undefined) {
      this.storage.set('darkmode', false);
    }
  }

  async getLocalImage() {
    const localImg = await this.storage.get('localImage');

    if (localImg === null || localImg === undefined) {
      this.storage.set('localImage', true);
    }
  }
}
